import React from "react"
import Image404 from "../images/404.svg"
import { Link } from "gatsby"
import SEO from "../components/utils/SEO"
import DefaultLayout from "../components/layouts/defaultLayout"

const NotFoundPage = () => (
  <DefaultLayout>
    <SEO title="404" />
    <section>
    <h1>Oopsie !</h1>
    <p>Tu viens d'atterir sur un page qui n'existe pas.</p>
    <p><Link to="/">Retourne sur l'acceuil</Link></p>
    <img src={Image404} style={{maxWidth:"50%",padding:'100px 0'}}/>

    </section>
  </DefaultLayout>
)

export default NotFoundPage
